.footer {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(193, 39, 54, 1);
    height: 105px;
    width: 100vw; 
    position: relative;
    bottom: 0;

}

.linkLogo {
    height: 32px;
    width: auto;
    margin: 0 30px;
}

@media (max-width: 500px) {
    .footer {
        height: 50px;
    }
    
    .linkLogo {
        height: 20px;
        margin: 0 15px;
    }
}
